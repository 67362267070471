<template>
  <div id="main">
    <div id="banner" @click="goBack">
      澜渟品牌资讯中心<span v-if="isPreview">预览页面</span>
    </div>
    <div id="article" v-if="detail.id">
      <div class="title">{{ detail.title }}</div>
      <div class="date">发布时间：{{ detail.gmt_create }}</div>
      <div class="article-content" v-html="detail.content"></div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/article.css'

export default {
  data() {
    return {
      code: this.$route.params.code,
      detail: {},
      isPreview: this.$route.query.p === 'fromP',
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.initDetail()
  },
  methods: {
    /**
     初始化热招岗位
     @param
     @return
     */
    initDetail() {
      this.$axios.post(`${this.$request.live}/common/pc/consultation-detail`, {code: this.code, is_preview: this.isPreview ? 1 : 0}).then(res => {
        if (res.data.id == 0) {
          this.$router.push({path: '/about/consultation'})
          return
        }
        this.detail = res.data
        // 设置 img
        this.$nextTick(() => {
          const imgs = document.querySelectorAll(".article-content img");
          imgs.forEach(img => {
            let attrWidth = img.attributes.width.value;
            let attrHeight = img.attributes.height.value;
            let displayWidth = img.clientWidth;
            let displayHeight = img.clientHeight;
            if (attrWidth / attrHeight !== displayWidth / displayHeight) {
              // 宽度 用 clientWidth
              let width = displayWidth;
              let height = width * (attrHeight / attrWidth);
              img.setAttribute("style", `width: ${width}px; height: ${height}px;`);
            }
          });
        });
      })
    },

    goBack() {
      this.$router.push({path: '/about/consultation'})
    }
  },
}
</script>

<style lang="scss" scoped>
#banner {
  position: sticky;
  top: 0.72rem;
  width: 100%;
  height: 0.72rem;
  background: #FAFAFA;
  z-index: 1;
  cursor: pointer;

  padding: 0 3.6rem;
  font-size: 0.18rem;
  font-weight: 500;
  color: #333333;
  line-height: 0.72rem;

  > span {
    position: absolute;
    font-size: 0.32rem;
    font-weight: bolder;
    font-style: italic;
    color: $md-color-primary;
    top: 0;
    right: 3.6rem;
  }
}

#article {
  position: relative;
  width: 12rem;
  margin: 0 auto;
  padding: 0.4rem 0 0.8rem 0;

  > .title {
    font-size: 0.26rem;
    font-weight: bolder;
    color: #333333;
    line-height: 0.4rem;
    margin-bottom: 0.08rem;
  }

  > .date {
    font-size: 0.14rem;
    color: #999999;
    line-height: 0.28rem;
    margin-bottom: 0.4rem;
  }

  > .article-content {
    overflow-x: hidden;
  }

}
</style>
